<template>
  <div class="fragment noPrintable">

<!--    <SearchEngine/>-->

    <div class="content-top-line content-top-line--with-bottom-line">

      <div class="content-top-line-wrap">
<!--        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />-->
      </div>
    </div>
    <div class="light-bg-block"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'generateReport_all',
          'generateReport_mine',
          'generateReport_rest',
          'generateReport_currentOrders',
        ])"></div>
      <div class="btn-left-block">
        <div class="btn-left-block-i">
          <router-link :to="$store.getters.GET_PATHS.reportsGenerateReportPage">
            <MainButton
                :value="$t('generateReport_all.localization_value.value')"
            ></MainButton>
          </router-link>
        </div>
        <div class="btn-left-block-i">
          <router-link :to="$store.getters.GET_PATHS.reportsGenerateReportPage + '?subType=mine'">
            <MainButton
                :value="$t('generateReport_mine.localization_value.value')"
            ></MainButton>
          </router-link>
        </div>
        <div class="btn-left-block-i">
          <router-link :to="$store.getters.GET_PATHS.reportsGenerateReportPage + '?subType=rest'">
            <MainButton
                :value="$t('generateReport_rest.localization_value.value')"
            ></MainButton>
          </router-link>
        </div>
      </div>
    </div>
    <div class="light-bg-block">
      <div class="btn-left-block">
        <div class="current-orders-scan" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'generateReport_scanHere',
          ])"></div>
          <DefaultInput
              ref="scanProductInput"
              :label="$t('generateReport_scanHere.localization_value.value')"
              :type="'text'"
              :resetAfter="true"
              @change="(val) => {changeScanner(val)}"
          />
        </div>
      </div>

      <div class="btn-right-block">
        <MainButton
            :value="$t('generateReport_resetCurrentOrders.localization_value.value')"
            class="mr-3 btn-fit-content white-space-nowrap"
            @click.native="resetCurrentOrders"
        />
        <MainButton
            :value="$t('generateReport_ship.localization_value.value')"
            class="mr-3"
            @click.native="openFromScanBinPopup"
        />
      </div>
    </div>


    <ScanOrderBinPopup
        v-if="showScanBinPopup"
        :orderId="orderId"
        :type="popupType"
        :allCheckedFlag="allCheckedFlag"
        :scannedProductId="scannedProductId"
        @close="changeScanBinPopup"
        @reload="$emit('reload')"
        @foundBin="closePopupAfterScan"
        @closeAfterScan="closePopupAfterScanChecked"
      />

    <BinInfoPopup
        v-if="showBinInfoPopup"
        :binName="binPopupName"
        :scannedProductId="scannedProductId"
        :orderId="orderId"
        @close="closeConfirmInfoPopup"
        @closeConfirm="closeConfirmInfoPopup"
    />

  </div>
</template>



<script>


  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import ScanOrderBinPopup from "../../../../popups/ScanOrderBinPopup/ScanOrderBinPopup";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import BinInfoPopup from "../../../../popups/BinInfoPopup/BinInfoPopup";
  import {generateReportFBMMixin} from "@/mixins/generateReportFBMMixins/generateReportFBMMixin";
  export default {
    name: "GenerateReportPageHead",
    components: {
      BinInfoPopup,
      MainButton,
      ScanOrderBinPopup,
      DefaultInput

    },

    mixins: [generateReportFBMMixin],

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
        printFile: null,
        showScanBinPopup: false,
        showBinInfoPopup: false,
        orderId: 0,
        popupType: '',
        binPopupName: '',
        allCheckedFlag: false,
        scannedProductId: null,
        dataPrepareSaveOrdersFBMToOrderBin: null,
        scannedLastProduct: false,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeScanBinPopup(val, type, orderId) {
        if (type === 'scan-to-bin' && orderId) {
          let orders = [...this.$store.getters.getGenerateReportCO]
          orders.forEach(item => {
            if (+item.order.id === +orderId && item.scanned === 1) {
              item.scanned = 0
              item.checked = false
            }
          })

          this.$store.commit('setGenerateReportCO', {generateReportCO: orders})
        }
        if (val === false) {
          this.focusScanProductInput()
        }

        this.showScanBinPopup = val
      },

      openFromScanBinPopup() {
        this.popupType = 'scan-from-bin'
        this.changeScanBinPopup(true)
      },

      closePopupAfterScan(data) {
        let orders = [...this.$store.getters.getGenerateReportCO]

        orders.forEach(item => {
          if (data.orderId === item.order.id && data.bin) {
            item.bin = data.bin

            if(Number(this.scannedProductId) === item.product.id && data.cell) {
              item.cell = data.cell
            }
          }
          if (data.orderId === item.order.id && !data.bin && item.scanned) {
            item.scanned = 0
            item.checked = false
          }
        })

        this.scannedProductId = null
        this.$store.commit('setGenerateReportCO', {generateReportCO: orders})

        this.changeScanBinPopup(false)
        this.focusScanProductInput()
        this.localSaveProgress()
      },

      closePopupAfterScanChecked() {
        this.changeScanBinPopup(false)
        this.focusScanProductInput()
        this.$emit('reload')
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },
      printTable() {

        window.print()
      },

      changeScanner(val) {

        let orders = [...this.$store.getters.getGenerateReportCO]
        let foundFlag = false
        let foundOrderId = null
        orders.forEach(item => {
          if (!item.checked && !foundFlag) {
            let noWSString = item.product.combo_name.replaceAll(' ', '')
            let startIndex = noWSString.indexOf('UPC[') + 4
            let endIndex = noWSString.indexOf(']', startIndex)
            let skuString = noWSString.substring(startIndex, endIndex)

            if (val === skuString) {
              this.scannedProductId = item.product.id
              if (item.scanned) {
                item.scanned++
              }
              else {
                item.scanned = 1
              }
              item.checked = item.scanned === item.product.order_item_quantity
              foundFlag = true
              foundOrderId = item.order.id
            }

          }
        })

        if (!foundFlag) return

        orders = orders.sort((a,b) => {
          if (a.checked && !b.checked) {
            return 1
          }
          if (!a.checked && b.checked) {
            return -1
          }

          return 0
        })

        this.$store.commit('setGenerateReportCO', {generateReportCO: orders})


        let orderItems = orders.filter(item => {
          return item.order.id === foundOrderId
        })

        let allChecked = true
        let totalScanned = 0
        orderItems.forEach(item => {
          if (!item.checked) {
            allChecked = false
          }
          if (item.scanned) {
            totalScanned += +item.scanned
          }
        })

        this.orderId = foundOrderId
        this.allCheckedFlag = allChecked

        if (totalScanned === 1) {
          //первый скан товара из ордера, показываем попап для скана юнита
          this.popupType = 'scan-to-bin'
          this.changeScanBinPopup(true)
        }

        if (totalScanned > 1 && !allChecked) {
          //второй и более скан товара из ордера, показываем попап что положено в юнит Х
          let findData = orderItems.find(findItem => {
            return +findItem?.order?.id === +foundOrderId
          })

          if (!findData) return

          this.binPopupName = findData.bin.name
          this.showBinInfoPopup = true
          this.localSaveProgress()
        }

        if (orderItems.length > 0 && allChecked && totalScanned > 1) {
          // когда всё отсканировано, показываем попап что положено в юнит Х и делаем запрос

          let findData = orderItems.find(findItem => {
            return +findItem?.order?.id === +foundOrderId
          })

          if (!findData) return

          this.binPopupName = findData.bin.name
          this.showBinInfoPopup = true
          this.localSaveProgress()

          this.dataPrepareSaveOrdersFBMToOrderBin = {
            foundOrderId: foundOrderId,
            findData: findData,
          }

          this.scannedLastProduct = true

        }
      },

      saveOrdersFBMToOrderBinFunc() {
        let foundOrderId = this.dataPrepareSaveOrdersFBMToOrderBin.foundOrderId
        let findData = this.dataPrepareSaveOrdersFBMToOrderBin.findData

        let data = {
          ids: [foundOrderId],
          orders_bin_id: findData?.bin?.id,
          products: [],
        }

        this.sendSaveOrdersFBMToOrderBin(data, foundOrderId).then(response => {
          if(!response.status) {
            this.resetCheckedLocalStorageByOrderId(response.foundOrderId)
          }
          this.$emit('reload')
        })
      },

      localSaveProgress() {
        let localStoreOrders = this.$store.getters.getGenerateReportCO.filter(item => {
          return +item.scanned
        })

        localStoreOrders = localStoreOrders.map(item => {
          return {
            orderId: item.order.id,
            productId: item.product.id,
            bin: item.bin,
            cell: item?.cell,
            scanned: item.scanned,
            checked: item.checked,
            count: item.product.order_item_quantity
          }
        })

        localStorage.setItem('currentOrdersLocal', JSON.stringify(localStoreOrders))
        localStorage.setItem('currentOrdersUserId', this.$store.getters.GET_COMMON_AUTHORIZED.user.id)
      },

      resetCurrentOrders() {
        let elements, idsArray = []
        elements = document.querySelectorAll('[data-order-id]:checked')

        elements.forEach((item) => {
          idsArray.push(+item.getAttribute('data-order-id'))
        })

        if (idsArray.length === 0) return

        let data = {
          'ids': idsArray
        }

        this.$store.dispatch('resetCurrentOrders', data).then(response => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              let orders = [...this.$store.getters.getGenerateReportCO]
              orders = orders.filter(orderItem => {
                return !idsArray.includes(orderItem.order.id)
              })
              this.$store.commit('setGenerateReportCO', {generateReportCO: orders})
              this.localSaveProgress()
              this.$emit('reload')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      focusScanProductInput() {
        setTimeout(() => {
          if (this.$refs?.scanProductInput?.$el?.children?.length) {
            this.$refs.scanProductInput.$el.children[0].focus()
          }
        }, 500)
      },

      closeConfirmInfoPopup(data) {
        if(data) {
          let orders = [...this.$store.getters.getGenerateReportCO]

          orders.forEach(item => {
            if (data.orderId === item.order.id && data.cell && Number(data.productId) === item.product.id) {
              item.cell = data.cell
            }
          })

          this.scannedProductId = null
          this.$store.commit('setGenerateReportCO', {generateReportCO: orders})
          this.localSaveProgress()

          if (this.scannedLastProduct) {
            this.saveOrdersFBMToOrderBinFunc()
          }
        }

        this.showBinInfoPopup = false
        this.focusScanProductInput()
      },
    },
  }
</script>

<style scoped lang="scss">
@media (max-width: 992px) {
  .main-button:not(.secondary):hover {
    background: #8f7a61 !important;
  }
}
@media (max-width: 680px) {
  .btn-left-block-i {
    margin-left: 10px;
  }

  .btn-left-block {
    .main-button {
      height: 35px;
      font-size: 14px;
      padding: 0 8px;
    }
  }
}

@media (max-width: 540px) {
  .btn-left-block-i {
    margin-left: 10px;
  }

  .btn-right-block {
    flex-direction: column;
    padding-left: 10px;

    .main-button {
      margin-bottom: 6px;
    }
  }
}
</style>

<style media="print">

  .print-btn-head{
    max-width: 167px;
  }

  @page { size: portrait; }

  @media print {
    * {
      -webkit-print-color-adjust: exact;
    }
    body .noPrintable {
      display: none;
    }

    .default-layout__page-wrap{
      padding-left: 0 !important;
    }
    .manager-btn{
      display: none !important;
    }

    .tooltip-helper.max-width-helper{
      width: 250px !important;
    }
    .tooltip-helper-full{
      white-space: initial !important;
      max-width: 200px !important;
    }

    .header__inner{
      display: none !important;
    }

    .default-layout__page-content{
      margin-top: 30px;
    }

    .site-table-wrap td:nth-child(5) span{
      display: block;
      max-width: 100px;
      white-space: initial !important;
    }

    .site-table thead th {
      font-size: 9px;
    }

    .site-table tbody td {
      font-size: 9px;
    }

    .site-table .table-link {
      font-size: 9px;
    }

    .site-table th:nth-child(3) {
      width: 20%;
    }

    .site-table td:nth-child(3) {
      width: 20%;
    }
  }
</style>

