export const generateReportFBMMixin = {

  methods: {
    async sendSaveOrdersFBMToOrderBin(data, foundOrderId) {
      let orders = [...this.$store.getters.getGenerateReportCO]
      orders.forEach(item => {
        if(foundOrderId === item.order.id) {
          data.products.push({
            order_fbm_id: foundOrderId,
            product_id: item.product.id,
            cell_id: item?.cell?.id || null,
            quantity: item.product.order_item_quantity
          })
        }
      })

      return this.$store.dispatch('saveOrdersFBMToOrderBin', data).then(response => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            this.dataPrepareSaveOrdersFBMToOrderBin = null
            this.openNotify('success', 'common_notificationRecordChanged')
            return {status: true}
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            // let errors = response.data.errors;
            // this.notifyErrorHelper(errors)
            this.openNotify('error', 'common_notificationErrProductCell')
            return {status: false, foundOrderId: foundOrderId}
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
            return {status: false, foundOrderId: foundOrderId}
          }
        }
      }).catch(error => this.createErrorLog(error))
    },

    resetCheckedLocalStorageByOrderId(id) {
      let localStoreOrders = JSON.parse(localStorage.getItem('currentOrdersLocal'))

      localStoreOrders = localStoreOrders.filter(item => {
        return Number(item.orderId) !== Number(id)
      })

      localStorage.setItem('currentOrdersLocal', JSON.stringify(localStoreOrders))
    },
  },



}