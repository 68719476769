<template>
  <modal
      @close="$emit('close',  false, type, orderId)"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
      <div
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['generateReport_scanBin'])"></span>
        {{$t('generateReport_scanCell.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row" v-if="type !== 'scan-from-bin'">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['generateReport_scanCell'])"></span>
            <DefaultInput
                ref="scanCellInput"
                :label="$t('generateReport_scanCell.localization_value.value')"
                v-model="scanCell"
                @change="changeCell"
            />
          </div>
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <MainButton
                class="wfc"
                :value="$t('productForCell_GoodsWithoutCells.localization_value.value')"
                @click.native="showScan = true"
            />
          </div>
        </div>

        <div class="custom-row" v-if="showScan || type === 'scan-from-bin'">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['generateReport_scanHere'])"></span>
            <DefaultInput
                ref="scanBinInput"
                :label="$t('generateReport_scanBin.localization_value.value')"
                v-model="scan"
                @change="scanBin"
            />
          </div>
        </div>

        <template v-if="showOrders.length > 0">
          <div class="custom-row">
            <div class="custom-col">
              <div class="site-table-wrap table-small">
                <table class="site-table min-w-initial">
                  <thead>
                    <tr>
                      <th width="100%">Id</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in showOrders" :key="index">
                      <td>{{item.id}}</td>
                      <td>
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :target="'_blank'"
                            :link="item.link"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <template slot="footer">
<!--      <div class="custom-popup__btn align-items-center d-flex flex-wrap"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit" @click="editTranslate(['common_cancel','common_save'])"></span>-->
<!--        <span-->
<!--            class="site-link site-link&#45;&#45;alt custom-popup__btn-i mr-4 mt-3 mb-3"-->
<!--            @click="$emit('close')"-->
<!--        >-->
<!--          {{$t('common_cancel.localization_value.value')}}-->
<!--        </span>-->
<!--        <MainButton-->
<!--            class="custom-popup__btn-i "-->
<!--             :value="$t('common_save.localization_value.value')"-->
<!--            @click.native="save"-->
<!--        />-->
<!--      </div>-->
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  // import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {FBM_ORDER_TYPES} from "../../../../../staticData/staticVariables";
  import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";
  import {generateReportFBMMixin} from "@/mixins/generateReportFBMMixins/generateReportFBMMixin";
  // import TextareaDefault from "../../../../UI/textarea/TextareaDefault/TextareaDefault";

  export default {
    name: "ScanOrderBinPopup",
    components: {
      MainButton,
      LinkButton,
      // TextareaDefault,
      DefaultInput,
      Modal,
      // MainButton,
    },

    mixins: [generateReportFBMMixin],

    props: {
      orderId: Number,
      scannedProductId: [Number, String],
      type: String,
      allCheckedFlag: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        scan: '',
        showScan: false,
        scanCell: '',
        cell: null,
        bins: [],
        showOrders: []
      }
    },



    mounted() {
      this.$store.dispatch('fetchOrderBin', '?limit=1000').then(response => {
        this.bins = this.getRespData(response)?.data
        console.log(this.bins);
      }).catch(error => this.createErrorLog(error))

      setTimeout(() => {
        if (this.$refs?.scanCellInput?.$el?.children?.length) {
          this.$refs.scanCellInput.$el.children[0].focus()
        }
      }, 500)

    },

    methods: {
      changeCell(val) {
        if (val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0) {
          let idCell = Number(val.split('-')[1])
          return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', `?filter[id]=${idCell}`).then(response => {
            if(this.getRespPaginateData(response).length > 0) {
              this.scanCell = this.getRespPaginateData(response)[0]?.combo_name
              this.cell = this.getRespPaginateData(response)[0]
              this.showScan = true
              setTimeout(() => {
                if (this.$refs?.scanBinInput?.$el?.children?.length) {
                  this.$refs.scanBinInput.$el.children[0].focus()
                }
              }, 500)
            }
          })
        }
      },

      scanBin() {

        if (this.scan.trim().toLowerCase().indexOf('bin_') !== 0) return

        let binScanId = this.scan.trim().toLowerCase().replace('bin_', '')

        let foundBin = this.bins.find(item => {
          return +item.id === +binScanId
        })

        if (foundBin && this.type === 'scan-to-bin') {
          console.log(foundBin);

          if (foundBin?.user?.id !== this.$store.getters.GET_COMMON_AUTHORIZED.user.id) {
            this.openNotify('error', 'orderBin_notOwnerError')
            this.scan = ''
            return
          }

          let data = {
            'scanned_bin' : 'bin_' + foundBin.id
          }
          this.$store.dispatch('getOrdersFBMInOrderBin', data).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let respData = this.getRespPaginateData(response)

                //check if already assigned unit to some order (but not sent)
                let alreadyScannedFlag = false
                this.$store.getters.getGenerateReportCO.forEach(coItem => {
                  if (+coItem?.bin?.id === foundBin.id) alreadyScannedFlag = true
                })

                if (foundBin.mixed !== 1 && (respData.length > 0 || alreadyScannedFlag)) {
                  this.openNotify('error', 'orderBin_notMixedOccupied')
                  this.scan = ''
                  return
                }

                if (!this.allCheckedFlag) {
                  this.$emit('foundBin', {bin: foundBin, orderId: this.orderId, cell: this.cell})
                }
                else {
                  let orders = [...this.$store.getters.getGenerateReportCO]

                  orders.forEach(item => {
                    if (this.orderId === item.order.id && foundBin) {
                      item.bin = foundBin

                      if(Number(this.scannedProductId) === item.product.id && this.cell) {
                        item.cell = this.cell
                      }
                    }
                  })

                  let data = {
                    ids: [this.orderId],
                    orders_bin_id: foundBin.id,
                    products: [],
                  }

                  this.sendSaveOrdersFBMToOrderBin(data, this.orderId).then(response => {
                    if(response.status) {
                      this.$emit('closeAfterScan')
                    } else {
                      this.resetCheckedLocalStorageByOrderId(response.foundOrderId)
                      this.$emit('close')
                      this.$emit('reload')
                    }
                  })

                  // let orders = [...this.$store.getters.getGenerateReportCO]
                  // orders.forEach(item => {
                  //   if(this.orderId === item.order.id) {
                  //     data.products.push({
                  //       order_fbm_id: this.orderId,
                  //       product_id: item.product.id,
                  //       cell_id: item?.cell?.id || null,
                  //       quantity: item.product.order_item_quantity
                  //     })
                  //   }
                  // })
                  //
                  // this.$store.dispatch('saveOrdersFBMToOrderBin', data).then(response => {
                  //   switch (this.getResponseStatus(response)) {
                  //       /**
                  //        * Success
                  //        */
                  //     case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                  //       this.openNotify('success', 'common_notificationRecordChanged')
                  //       break
                  //     }
                  //       /**
                  //        * Validation Error
                  //        */
                  //     case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                  //       let errors = response.data.errors;
                  //       this.notifyErrorHelper(errors)
                  //       break
                  //     }
                  //       /**
                  //        * Undefined Error
                  //        */
                  //     default: {
                  //       this.openNotify('error', 'common_notificationUndefinedError')
                  //     }
                  //   }
                  // }).catch(error => this.createErrorLog(error))
                }

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          }).catch(error => this.createErrorLog(error))
        }

        if (foundBin && this.type === 'scan-from-bin') {

          let data = {
            'scanned_bin' : 'bin_' + foundBin.id
          }
          this.$store.dispatch('getOrdersFBMInOrderBin', data).then(response => {
            switch (this.getResponseStatus(response)) {
                /**
                 * Success
                 */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                let orders = []
                let respData = this.getRespPaginateData(response)

                respData.forEach(item => {
                  let findOrder = orders.find(findItem => { return findItem.id === item?.order.id})
                  if (!findOrder) orders.push(item.order)
                })

                if (orders.length === 1) {
                  orders.forEach(item => {
                    window.open(this.$store.getters.GET_PATHS.ordersFBM + '/' + this.getFBMType(item.type) + '/' + item.id, '_blank')
                  })

                  this.scan = ''
                  this.$emit('close',  false, this.type, this.orderId)
                  return
                }
                else if (orders.length > 1) {
                  orders.forEach(item => {
                    this.showOrders.push({
                      id: item.id,
                      link: this.$store.getters.GET_PATHS.ordersFBM + '/' + this.getFBMType(item.type) + '/' + item.id
                    })
                  })

                  this.scan = ''
                  return
                }

                break
              }
                /**
                 * Validation Error
                 */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
                /**
                 * Undefined Error
                 */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }
          }).catch(error => this.createErrorLog(error))
        }

        this.scan = ''
      },

      getFBMType(type) {
        let actualType = ''

        switch (type) {
          case 'consolidation':
            actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
            break
          case 'sklad_create':
            actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
            break
          case 'user_create':
            actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
            break
        }

        return actualType
      },
    }
  }
</script>

<style scoped>

</style>
