var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment noPrintable"},[_vm._m(0),_c('div',{staticClass:"light-bg-block",class:{'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'generateReport_all',
          'generateReport_mine',
          'generateReport_rest',
          'generateReport_currentOrders',
        ])}}}),_c('div',{staticClass:"btn-left-block"},[_c('div',{staticClass:"btn-left-block-i"},[_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsGenerateReportPage}},[_c('MainButton',{attrs:{"value":_vm.$t('generateReport_all.localization_value.value')}})],1)],1),_c('div',{staticClass:"btn-left-block-i"},[_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsGenerateReportPage + '?subType=mine'}},[_c('MainButton',{attrs:{"value":_vm.$t('generateReport_mine.localization_value.value')}})],1)],1),_c('div',{staticClass:"btn-left-block-i"},[_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.reportsGenerateReportPage + '?subType=rest'}},[_c('MainButton',{attrs:{"value":_vm.$t('generateReport_rest.localization_value.value')}})],1)],1)])]),_c('div',{staticClass:"light-bg-block"},[_c('div',{staticClass:"btn-left-block"},[_c('div',{staticClass:"current-orders-scan",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
            'generateReport_scanHere',
          ])}}}),_c('DefaultInput',{ref:"scanProductInput",attrs:{"label":_vm.$t('generateReport_scanHere.localization_value.value'),"type":'text',"resetAfter":true},on:{"change":(val) => {_vm.changeScanner(val)}}})],1)]),_c('div',{staticClass:"btn-right-block"},[_c('MainButton',{staticClass:"mr-3 btn-fit-content white-space-nowrap",attrs:{"value":_vm.$t('generateReport_resetCurrentOrders.localization_value.value')},nativeOn:{"click":function($event){return _vm.resetCurrentOrders.apply(null, arguments)}}}),_c('MainButton',{staticClass:"mr-3",attrs:{"value":_vm.$t('generateReport_ship.localization_value.value')},nativeOn:{"click":function($event){return _vm.openFromScanBinPopup.apply(null, arguments)}}})],1)]),(_vm.showScanBinPopup)?_c('ScanOrderBinPopup',{attrs:{"orderId":_vm.orderId,"type":_vm.popupType,"allCheckedFlag":_vm.allCheckedFlag,"scannedProductId":_vm.scannedProductId},on:{"close":_vm.changeScanBinPopup,"reload":function($event){return _vm.$emit('reload')},"foundBin":_vm.closePopupAfterScan,"closeAfterScan":_vm.closePopupAfterScanChecked}}):_vm._e(),(_vm.showBinInfoPopup)?_c('BinInfoPopup',{attrs:{"binName":_vm.binPopupName,"scannedProductId":_vm.scannedProductId,"orderId":_vm.orderId},on:{"close":_vm.closeConfirmInfoPopup,"closeConfirm":_vm.closeConfirmInfoPopup}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-top-line content-top-line--with-bottom-line"},[_c('div',{staticClass:"content-top-line-wrap"})])
}]

export { render, staticRenderFns }