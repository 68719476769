<template>
  <div>
    <CurrentOrdersHead
        :showFilter="showFilter"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @reload="$emit('reload')"
    />

    <div class="table-filter-wrap">

      <CurrentOrdersTable
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @selectAll="(val) => $emit('selectAll', val)"
      />
    </div>
  </div>
</template>

<script>


  import CurrentOrdersHead from "./CurrentOrdersHead/CurrentOrdersHead";
  import CurrentOrdersTable from "./CurrentOrdersTable/CurrentOrdersTable";

  export default {
    name: "CurrentOrdersTableAdmin",

    components: {
      CurrentOrdersHead,
      CurrentOrdersTable,
    },

    props: {

    },

    data(){
      return{
        showFilter: false,
        activeTab: 1,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      changeShopType(index) {
        this.$emit('changeShopType', index)
      },


      changeFilter(data) {
        this.$emit('changeFilter', data)
      },

      changeTab(id) {
        this.activeTab = id
      },
    }
  }
</script>

<style scoped>

</style>
