<template>
  <div class="fragment">
    <div class="pt-2 pb-3" v-if="$store.getters.getGenerateReportCOLoading">
      <DotsShadowPreloader/>
    </div>

    <template v-if="!$store.getters.getGenerateReportCOLoading">
      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_currentOrders',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_currentOrders.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table table-overflow"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
            'generateReport_Unit',
          ])"></div>
<!--        !isMobileFunc() &&-->
          <table class="site-table site-table-current" v-if=" _.has($store.getters.getGenerateReportCommonListCO, 'data')">
            <thead>
            <tr>
              <th>
                <DefaultCheckbox
                    class="empty-label white brown-border noPrintable"
                    @input="(val) => $emit('selectAll', val)"
                />
              </th>
              <th>{{ $t('generateReport_User.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
              <th>{{ $t('generateReport_ShippingMethod.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
              <th>{{ $t('generateReport_Unit.localization_value.value') }}</th>
              <th></th>
              <th width="100%"></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(report, index) of $store.getters.getGenerateReportCO" :key="index">
              <td>
                <DefaultCheckbox
                    v-if="index === 0 || (index > 0 && $store.getters.getGenerateReportCO[index - 1] && $store.getters.getGenerateReportCO[index - 1].order
                  && $store.getters.getGenerateReportCO[index - 1].order.id && report.order && report.order.id && report.order.id !== $store.getters.getGenerateReportCO[index - 1].order.id)"
                    class="empty-label"
                    :dataOrderId="report.order.id"
                />
              </td>
              <td>
                <TableUserColumn
                    :item="report"/>
              </td>
              <td>
                <router-link class="table-link"
                             target="_blank"
                             :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">
                  {{ report.order.id }}
                </router-link>
                <br>
                {{ report.order.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.order.created_at | moment("HH:mm:ss") }}
              </td>
              <td>
                <div class="table-row">
                  <div class="print-div--mw280" style="max-width: 430px">
                    <router-link class="table-link"
                                 target="_blank"
                                 :to="$store.getters.GET_PATHS.products + '/' + report.product.id + ($route.query.storage ? `?storageId=${$route.query.storage}` : '')">
                      {{ report.product.combo_name }}
                    </router-link>
                  </div>
                </div>
              </td>
              <td>

                <router-link class="table-link"
                             target="_blank"
                             v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"
                             :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(report.product.last_inbound.consolidation_destination_id) + '/' + report.product.last_inbound.order_model_id">
                  <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>
                </router-link>
                <router-link class="table-link"
                             target="_blank"
                             v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"
                             :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                  <ValueHelper
                      :value="report"
                      :deep="'product.last_inbound.order_model_id'"
                  />
                </router-link>
                <br>
                <template v-if="_.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id">
                  {{ report.product.last_inbound.consolidation_created_at | moment("DD MMM, YYYY") }}
                  <br>
                  {{ report.product.last_inbound.consolidation_created_at | moment("HH:mm:ss") }}
                </template>
                <template v-else-if="report.product.last_inbound">
                  {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                  <br>
                  {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
                </template>
              </td>
              <td>
                <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">
                  DPD
                </template>
                <ValueHelper
                    v-else
                    :value="report"
                    :deep="'order.delivery_method.name'"
                />
              </td>
              <td>
                {{ report.product.order_item_quantity }}
              </td>
              <td>
                <template v-for="comboName of report.product.items_in_storage">
                  <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                    {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                  </div>
                </template>
              </td>

              <td>
                <template v-if="report.bin">
                  {{report.bin.name}}
                </template>
              </td>

              <td width="100%"></td>
              <td>
                <div class="d-flex align-items-center">
                  <DefaultCheckbox
                      class="d-inline-block mr-2"
                      :disabled="true"
                      :value="report.checked ? report.checked : false"
                  />
                  <div class="d-inline-block">
                    {{report.scanned ? report.scanned: 0}} / {{ report.product.order_item_quantity }}
                  </div>
                </div>

              </td>
              <td align="right">
<!--                    v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"-->
                <LinkButton
                    class="mr-3 noPrintable"
                    :link="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id"
                    :value="$t('generateReport_edit.localization_value.value')"
                    :type="'edit'"
                    :target="'_blank'"
                />
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-if="false && isMobileFunc() && _.has($store.getters.getGenerateReportCommonListCO, 'data')">
            <div class="table-card__list pt-0">
              <div class="table-card__item"
                   v-for="(report, index) in $store.getters.getGenerateReportCO"
                   :key="index">
                <CurrentOrdersTableMobile
                    :report="report"
                    :scannedVal="report.scanned"
                    :index="index"
                />
              </div>
            </div>
          </div>
        </div>

    </template>

    <!-- READY TO GO SECTION -->
    <div class="pt-2 pb-3" v-if="$store.getters.getGenerateReportInUnitsLoading">
      <DotsShadowPreloader/>
    </div>

    <template v-if="!$store.getters.getGenerateReportInUnitsLoading">

      <div class="fragment"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_readyToGo',
          ])"></div>
        <div class="before-time-block">
          <div class="before-time-block__item line">
            <div class="before-time-block__time">
              {{ $t('generateReport_readyToGo.localization_value.value') }}
            </div>
          </div>
        </div>
      </div>

      <div class="site-table-wrap small-table table-overflow"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'generateReport_User',
            'generateReport_Order',
            'generateReport_Name',
            'generateReport_Inbound',
            'generateReport_ShippingMethod',
            'generateReport_Quality',
            'generateReport_Cell',
            'generateReport_Unit',
          ])"></div>
<!--        !isMobileFunc() &&-->
        <table class="site-table" v-if=" _.has($store.getters.getGenerateReportCommonListInUnits, 'data')">
          <thead>
          <tr>
            <th></th>
            <th>{{ $t('generateReport_User.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Order.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Name.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Inbound.localization_value.value') }}</th>
            <th>{{ $t('generateReport_ShippingMethod.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Quality.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Cell.localization_value.value') }}</th>
            <th>{{ $t('generateReport_Unit.localization_value.value') }}</th>
            <th></th>
            <th width="100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(report, index) of $store.getters.getGenerateReportInUnits" :key="index">
            <td></td>
            <td>
              <TableUserColumn
                  :item="report"/>
            </td>
            <td>
              <router-link class="table-link"
                           target="_blank"
                           :to="$store.getters.GET_PATHS.ordersFBM + '/show/' + getFBMType(report.order.type) + '/' + report.order.id">
                {{ report.order.id }}
              </router-link>
              <br>
              {{ report.order.created_at | moment("DD MMM, YYYY") }}
              <br>
              {{ report.order.created_at | moment("HH:mm:ss") }}
            </td>
            <td>
              <div class="table-row">
                <div class="print-div--mw280" style="max-width: 430px">
                  <router-link class="table-link"
                               target="_blank"
                               :to="$store.getters.GET_PATHS.products + '/' + report.product.id + ($route.query.storage ? `?storageId=${$route.query.storage}` : '')">
                    {{ report.product.combo_name }}
                  </router-link>
                </div>
              </div>
            </td>
            <td>

              <router-link class="table-link"
                           target="_blank"
                           v-if="report.product.last_inbound && _.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id"
                           :to="$store.getters.GET_PATHS.ordersConsolidationDetail + '/' +
                                getConsolidationTypeByDestinationId(report.product.last_inbound.consolidation_destination_id) + '/' + report.product.last_inbound.order_model_id">
                <span v-if="report.product.last_inbound.order_model_id">{{report.product.last_inbound.order_model_id}}</span>
              </router-link>
              <router-link class="table-link"
                           target="_blank"
                           v-else-if="report.product.last_inbound && _.has(report.product.last_inbound, 'inbound_id') && report.product.last_inbound.inbound_id"
                           :to="$store.getters.GET_PATHS.ordersShipmentWarehouse + '/detail/' +
                    getOrderType(report.product.last_inbound['fulfillment'], report.product.last_inbound['return'] === 1).value + '/' + report.product.last_inbound.order_model_id">
                <ValueHelper
                    :value="report"
                    :deep="'product.last_inbound.order_model_id'"
                />
              </router-link>
              <br>
              <template v-if="_.has(report.product.last_inbound, 'consolidation_destination_id') && report.product.last_inbound.consolidation_destination_id">
                {{ report.product.last_inbound.consolidation_created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.consolidation_created_at | moment("HH:mm:ss") }}
              </template>
              <template v-else-if="report.product.last_inbound">
                {{ report.product.last_inbound.created_at | moment("DD MMM, YYYY") }}
                <br>
                {{ report.product.last_inbound.created_at | moment("HH:mm:ss") }}
              </template>
            </td>
            <td>
              <template v-if="report.order.delivery_method && report.order.delivery_method.name === 'DPD.cz'">
                DPD
              </template>
              <ValueHelper
                  v-else
                  :value="report"
                  :deep="'order.delivery_method.name'"
              />
            </td>
            <td>
              {{ report.product.order_item_quantity }}
            </td>
            <td>
              <template v-for="comboName of report.product.items_in_storage">
                <div v-if="comboName.cell !== null && comboName.cell.combo_name !== null" :key="comboName.id">
                  {{ comboName.cell.combo_name }} ({{comboName.quantity}})
                </div>
              </template>
            </td>
            <td>
              <template v-if="report.order_bins && report.order_bins.length > 0 && report.order_bins[0].name">
                {{report.order_bins[0].name}}
              </template>
            </td>
            <td width="100%"></td>
            <td>
              <div class="d-flex align-items-center">
                <DefaultCheckbox
                    class="d-inline-block mr-2"
                    :disabled="true"
                    :value="true"
                />
                <div class="d-inline-block">
                  {{ report.product.order_item_quantity }} / {{ report.product.order_item_quantity }}
                </div>
              </div>

            </td>
            <td align="right">
              <!--                    v-if="report.product.last_inbound && Object.keys(report.product.last_inbound).length > 0"-->
              <LinkButton
                  class="mr-3 noPrintable"
                  :link="$store.getters.GET_PATHS.ordersFBM + '/' + getFBMType(report.order.type) + '/' + report.order.id"
                  :value="$t('generateReport_edit.localization_value.value')"
                  :type="'edit'"
                  :target="'_blank'"
              />
            </td>
          </tr>
          </tbody>
        </table>

        <div class="table-card" v-if="false && isMobileFunc() && _.has($store.getters.getGenerateReportCommonListInUnits, 'data')">
          <div class="table-card__list pt-0">
            <div class="table-card__item"
                 v-for="(report, index) in $store.getters.getGenerateReportInUnits"
                 :key="index">
              <CurrentOrdersTableMobile
                  :report="report"
                  :readyToGo="true"
                  :index="index"
              />
            </div>
          </div>
        </div>
      </div>

    </template>

    <div class="table-bottom-btn">
      <div class="table-bottom-btn__inner">
      </div>
    </div>


  </div>
</template>

<script>

import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import CurrentOrdersTableMobile from "./CurrentOrdersTableMobile/CurrentOrdersTableMobile";
import {FBM_ORDER_TYPES} from "@/staticData/staticVariables";
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";
import {ordersInboundMixin} from "@/mixins/ordersInbound/ordersInboundMixin";
import DotsShadowPreloader from "@/components/coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
import {consolidationMixin} from "@/mixins/consolidationMixins/consolidationMixin";
import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


export default {
  name: "NewPaymentsTable",

  components: {
    DefaultCheckbox,
    DotsShadowPreloader,
    TableUserColumn,
    CurrentOrdersTableMobile,
    ValueHelper,
    // TooltipTextHelper,
    // TooltipBtn,
    // MainButton,
    // DefaultInput,
    LinkButton,
    // ProductsToWarehouse,
  },

  mixins: [mixinDetictingMobile, ordersInboundMixin, consolidationMixin],

  data() {
    return {
      orderId: '',
      showFilter: false,
      isMobile: false,

      showContent: false,

      visibleCount: 100,
      visibleDone: false,

      isModalStatisticPopup: false,
      userId: null,
    }
  },

  methods: {

    getCountOrders(orders) {
      let array = []
      orders.map(order => {
        if(!this._.find(array, {id: order.order.id})){
          array.push(order.order)
        }
      })
      return array.length
    },

    getFBMType(type) {
      let actualType = ''

      switch (type) {
        case 'consolidation':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_CONSOLIDATION.name
          break
        case 'sklad_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_WAREHOUSE.name
          break
        case 'user_create':
          actualType = FBM_ORDER_TYPES.FBM_ORDER_LABEL.name
          break
      }

      return actualType
    },

    closePopup() {
      // this.isModalProductsToWarehouse = false
    },

    cantDeleteProduct() {
      let text = {
        title: 'Ooops',
        txt: 'Sorry, you cannot uninstall this product',
        no: 'Ok'
      }

      let confirm = () => {
        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    removeProduct() {
      let text = {
        title: 'Delete Product?',
        txt: 'Are you sure you want to Delete Product?',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        // this.$store.dispatch('deleteShopPermission', id).then((response) => {
        //   if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //     let shops = this.$store.getters.getShops
        //     shops.map((item, index) => {
        //       if(item.id === id) {
        //         shops[index].shop_oauth_param.key_and_token_exist = 0
        //       }
        //     })
        //   } else {
        //     setTimeout(() => {
        //       this.openNotify('success', 'Произошла ошибка')
        //     }, 200)
        //   }
        // })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    }
  }

}

</script>

<style scoped lang="scss">

.site-table-loading{
  opacity: 0.5;
}

.comment-row {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 350px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.table-manage-list {
  width: 100%;

  .manager-btn {
    max-width: 100%;
  }
}


@media (max-width: 1900px) {
  .comment-row {
    width: 200px;
  }
}

@media (max-width: 1400px) {
  .comment-row {
    width: 150px;
  }
}

//.site-table thead th:first-child,
//.site-table tbody td:first-child{
//  padding-right: 0;
//}

//.site-table thead th:nth-child(2),
//.site-table tbody td:nth-child(2){
//  padding-left: 0;
//}

.site-table thead th:last-child,
.site-table tbody td:last-child {
  padding-right: 0;
}

.small-table th,
.small-table td {
  white-space: nowrap;
}

.small-table th:nth-child(4),
.small-table td:nth-child(4) {
  white-space: initial;
}

@media (min-width: 1300px) {
  .site-table thead th:nth-child(1) {
    min-width: 50px;
  }

  .site-table thead th:nth-child(2) {
    min-width: 250px;
  }

  .site-table thead th:nth-child(3) {
    min-width: 100px;
  }

  .site-table thead th:nth-child(4) {
    min-width: 320px;
  }

  .site-table thead th:nth-child(5) {
    min-width: 101px;
  }

  .site-table thead th:nth-child(6) {
    min-width: 230px;
  }

  .site-table thead th:nth-child(7) {
    min-width: 85px;
  }

  .site-table thead th:nth-child(8) {
    min-width: 95px;
  }
}


</style>


<style>

td div[data-user-id] {
  cursor: pointer;
  display: inline;
}

@media (max-width: 1860px) {
  .table-link.white-space-normal{
    max-width: 330px !important;
  }
}

.table-overflow {
  overflow: auto;
}

@media (max-width: 1200px) {
  .table-overflow th:nth-child(5),
  .table-overflow th:nth-child(6),
  .table-overflow th:nth-child(7),
  .table-overflow td:nth-child(5),
  .table-overflow td:nth-child(6),
  .table-overflow td:nth-child(7) {
    display: none;
  }
}

</style>