<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup"
  >
    <template slot="header">
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="custom-row" v-if="showFieldCell">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['generateReport_scanCell'])"></span>
            <DefaultInput
                ref="scanCellInput"
                :label="$t('generateReport_scanCell.localization_value.value')"
                v-model="scanCell"
                @change="changeCell"
            />
          </div>
        </div>

        <div class="custom-row">
          <div class="custom-col"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['generateReport_putInUnitText'])"></span>
            {{$t('generateReport_putInUnitText.localization_value.value')}} <b>{{binName}}</b>
          </div>
        </div>

      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['common_confirm'])"></span>
<!--        <span-->
<!--            class="site-link site-link&#45;&#45;alt custom-popup__btn-i mr-4 mt-3 mb-3"-->
<!--            @click="$emit('close')"-->
<!--        >-->
<!--          {{$t('common_cancel.localization_value.value')}}-->
<!--        </span>-->
        <MainButton
            class="custom-popup__btn-i "
             :value="$t('common_confirm.localization_value.value')"
            @click.native="$emit('closeConfirm', {productId: scannedProductId, orderId: orderId, cell: cell})"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "BinInfoPopup",
    components: {
      DefaultInput,
      Modal,
      MainButton,
    },

    props: {
      binName: String,
      scannedProductId: [Number, String],
      orderId: [Number, String],
    },

    data() {
      return {
        showFieldCell: false,
        scanCell: '',
        cell: null,
      }
    },

    mounted() {
      let localStoreOrders = this.$store.getters.getGenerateReportCO.filter(item => {
        return item.product.id === Number(this.scannedProductId) && Number(this.orderId) === item.order.id
      })

      if(localStoreOrders.length === 0) return

      if(!localStoreOrders[0]?.cell?.id) {
        this.showFieldCell = true
      }
    },

    methods: {
      changeCell(val) {
        if (val.length > 0 && val.indexOf('-') > 0 && val.toLowerCase().indexOf('cell') === 0) {
          let idCell = Number(val.split('-')[1])
          return this.$store.dispatch('getWarehouseStorageCellWithFilterPublic', `?filter[id]=${idCell}`).then(response => {
            if(this.getRespPaginateData(response).length > 0) {
              this.scanCell = this.getRespPaginateData(response)[0]?.combo_name
              this.cell = this.getRespPaginateData(response)[0]
            }
          })
        }
      },
    }
  }
</script>

<style scoped>

</style>
