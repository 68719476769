<template>
  <CurrentOrdersTableAdmin
      v-if="isAdmin"
      @showMore="showMore"
      @reload="reload"
      @selectAll="selectAll"
  />
</template>

<script>
  import CurrentOrdersTableAdmin from "./CurrentOrdersTableAdmin/CurrentOrdersTableAdmin";
  import { routeFilter } from "../../../../../mixins/routeFilterMixins/routeFilter";

  export default {
    name: "CurrentOrdersTable",
    components: {
      CurrentOrdersTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getGenerateReportForPage,
        page: 1,
        onePage: 1,

      }
    },
    mounted() {
      this.filter();
    },
    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,

        }
      },

      filter(next = false) {
        this.getFilterParam()

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this);

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL

        let url = this.generateFilterUrl(
            generateMainFilter.page,
            generateMainFilter.forPage
        )

        if (next)
          this.$store.commit('setNextGenerateReportPage', true)

        this.$store.dispatch('fetchCurrentOrders', url).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)

          //set local changes like scanned products or bin
          if (localStorage.getItem('currentOrdersUserId') &&
              +localStorage.getItem('currentOrdersUserId') === +this.$store.getters.GET_COMMON_AUTHORIZED.user.id) {
            let localData = JSON.parse(localStorage.getItem('currentOrdersLocal'))
            let orders = [...this.$store.getters.getGenerateReportCO]
            localData.forEach(localItem => {
              orders.forEach(orderItem => {
                if (+orderItem.order.id === +localItem.orderId && +orderItem.product.id === +localItem.productId) {
                  orderItem.scanned = +localItem.scanned
                  orderItem.checked = localItem?.checked ? true : false
                  orderItem.bin = localItem.bin
                  orderItem.cell = localItem?.cell
                }
                //set unit for not scanned items in order
                else if (+orderItem.order.id === +localItem.orderId && !orderItem.bin) {
                  orderItem.bin = localItem.bin
                }
              })
            })

            orders = orders.sort((a,b) => {
              if (a.checked && !b.checked) {
                return 1
              }
              if (!a.checked && b.checked) {
                return -1
              }

              return 0
            })

            this.$store.commit('setGenerateReportCO', {generateReportCO: orders})
          }
          else if (localStorage.getItem('currentOrdersUserId') &&
              +localStorage.getItem('currentOrdersUserId') !== +this.$store.getters.GET_COMMON_AUTHORIZED.user.id) {
            localStorage.removeItem('currentOrdersUserId')
            localStorage.removeItem('currentOrdersLocal')
          }

        })

        this.$store.dispatch('fetchOrdersInUnits', url).then(() => {
          this.$store.commit('setGlobalUpdateTable', false)
        })


        this.checkCountFilter()
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()

        this.generateFilterQueryParams(
            myQuery,{},
        )

        let url = this.generateGetParamsFromQuery(myQuery, forPage, page)

        return url
      },

      selectAll(val) {
        let elements = document.querySelectorAll('[data-order-id]')
        elements.forEach(item => {
          item.checked = val
        })
      }
    }
  }
</script>

<style scoped>

</style>
